import "../App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useWindowSize } from "../lib/hooks/useWindowSize";
import { Loading } from "./Loading";

Modal.setAppElement("#root");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("jp-JP"); // ここでUIの言語が変更できる
ZoomMtg.i18n.reload("jp-JP"); // ここでUIの言語が変更できる

function ZoomInstantMeeting(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { width: responsiveWidth, height: responsiveHeight } = useWindowSize();
  const meetingNumber = props.match.params.meeting_number;
  const encodedPassword = props.match.params.password
    .replace(/-/g, "+")
    .replace(/_/g, "/");
  const decodedPassword = decodeURIComponent(escape(atob(encodedPassword)));
  const encodedUserName = props.match.params.user_name
    .replace(/-/g, "+")
    .replace(/_/g, "/");
  const decodedUserName = decodeURIComponent(escape(atob(encodedUserName)));
  let diplayName;
  if (decodedUserName.includes('"') || decodedUserName.includes("'")) {
    diplayName = decodedUserName.replace(/["']/g, "");
  } else {
    diplayName = decodedUserName;
  }
  var signatureEndpoint = process.env.REACT_APP_ZOOM_SDK_SIGNATURE_ENDPOINT;
  //var signatureEndpoint = "http://localhost:4000"; // ローカル
  var role = 0; // host開催が1 でクライアント開催が0
  var leaveUrl =
    window.location.origin + "/j/thx/" + props.match.params.user_name;
  var registrantToken = "";

  function startMeeting(signature, sdkKey) {
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl,
      meetingInfo: ["topic", "enctype"],
      success: (success) => {
        console.log(success);
        setIsLoading(false);
        ZoomMtg.join({
          signature: signature,
          meetingNumber,
          userName: diplayName,
          passWord: decodedPassword,
          sdkKey,
          tk: registrantToken,
          success: (success) => {
            setIsLoading(false);
            localStorage.removeItem("reloadCount");
            localStorage.setItem("meetingAttended", "true"); // ミーティングに参加した記録をlocalStorageに保存;
            console.log(success);
            //zoom sdk3.1.6のバグの修正対応。（レコーディングモーダルが退出時ちらつく問題）
            ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
              console.log("data.reason[0]:", data.reason[0]);
              console.log("data.reason:", data.reason[0] === "Y"); // true

              // data.reason[0]で自分が退出した状態を取得（メッセージの頭文字）
              if (data.reason[0] === "Y") {
                //退出したらオリジナルモーダルが一瞬表示されるレイヤーに無理やりzoomのメイン要素のレイヤーを最上位にかぶせて出ないようにする対策
                document.getElementById("zmmtg-root").style.zIndex = "11000"; //モーダルが9999なのでそれより上の数でレイヤーをマウント
              }
            });
          },
          error: (error) => {
            console.warn(error, "無効なパラメータ");
            // props.history.goBack();
            // TODO: ERROR HANDRING どのパラメータで入れないかレスポンスがあるので参考にハンドリング
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  function join() {
    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        //APIエラーレスポンスの判定およびメッセージの保存
        if (response.id) {
          setErrorMessage(response.id + " : " + response.message);
          setIsLoading(false);
          return;
        }
        const arrayedSignatureData = response.signature.split(".");
        const payload = Buffer.from(arrayedSignatureData[1], "base64").toString(
          "ascii"
        );
        const decodedPayload = JSON.parse(payload);
        startMeeting(response.signature, decodedPayload.sdkKey);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.toString());
        console.error(error);
      });
  }

  //　リダイレクトと共に会議参加
  useEffect(() => {
    join();
  }, []);

  useEffect(() => {
    const { history, location } = window;
    history.pushState(null, null, location.href);
    const handlePopstate = () => {
      alert("この操作は禁止されています。");
      history.pushState(null, null, location.href);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-restricted-globals
  }, [location.href]);

  if (isLoading) {
    return <Loading />;
  }

  localStorage.getItem("meetingAttended"); // ミーティングに参加した記録をlocalStorageから取得;
  return (
    <>
      {/* 例外発生時のモーダル */}
      {errorMessage !== "" ? (
        <div className="alert">
          <p> {errorMessage}</p>
        </div>
      ) : null}

      {/* 一部モバイル端末で入力決定ボタンがポップアップキーボードに重なってしまうため、モバイル時のみ入力欄領域を上に上げる */}
      <main style={{ marginTop: responsiveWidth < 600 ? "-35px" : "0px" }}>
        {/* {isLoading ? <Loading /> : null} */}
      </main>
    </>
  );
}

export default ZoomInstantMeeting;
