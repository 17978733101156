import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ZoomMeeting from "./components/ZoomMeeting";
import ZoomMeetingOld from "./components/ZoomMeetingOld";
import ZoomInstatMeeting from "./components/ZoomInstatMeeting";
import ThxPage from "./components/ThxPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            path="/j/:meeting_number/:user_name/:password"
            component={ZoomInstatMeeting}
          />
          <Route path="/j/thx/:user_name" component={ThxPage} />
          <Route path="/j/:meeting_number/:user_name" component={ZoomMeeting} />
          <Route path="/j/:meeting_number" component={ZoomMeetingOld} />
          <Route path="/j" component={ZoomMeetingOld} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
